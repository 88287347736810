import { graphql } from "gatsby";
import * as React from "react";
import Layout from "~/components/Layout";
import { WpPage_Page_FlexibleContent } from "~/_generated/types";
import { Hero } from '~/components/flexible/Hero';
import { IconGrid } from '~/components/flexible/IconGrid';
import { Image } from '~/components/elements/Image';
import { ArticleRenderer } from '~/components/layouts/ArticleRenderer';
import { Button, ButtonType, ButtonSize, ButtonColor } from '~/components/elements/button/Button';
import { Icon, IconType } from '~/components/elements/Icon';
import { CustomersPostsList } from '~/components/flexible/CustomersPostsList';
import { useCustomerStories } from '~/hooks/useCustomerStories';

import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function customerSingle({
  data: { wpPage, wpCustomer, allWpCustomer, wp },
  }: {
    data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
  }) {

	const customerStory = wpCustomer.postTypeCustomer;
	
  return (
    <Layout wp={wp}>
      <Seo post={wpCustomer} title={wpCustomer.title} /> 

			<Hero
				image={customerStory.mastheadImage.localFile.publicURL}
				background="bg-pearl"
				headingSize="text-h3"
				preHeading={customerStory.mastheadSubheading}
				heading={customerStory.mastheadHeading}
				content={customerStory.mastheadContent}
				logo={customerStory.mastheadLogo}
				button={{ title: customerStory.mastheadLink.link.title, url: customerStory.mastheadLink.link.url, target: customerStory.mastheadLink.link.target }}
				noMobPad={true}
			/>
			{ customerStory.addIconGrid &&
				<section className="max-w-[1331px] mx-auto my-8 md:my-12 lg:mb-16 lg:mt-24">
					<IconGrid
						background="bg-pampas"
						preHeading={customerStory.iconGridSubheading}
						heading={customerStory.iconGridHeading}
						centred={true}
						gridItems={customerStory.gridItems}
						textLrg={true}
						listMaxWidth={true}
					/>
				</section>
			}

			<section className="section customer-article-content" id="article-content">
				<div className="container md:grid grid-cols-12 gap-12">
					<div className="md:col-span-6 lg:col-span-8">
						<ArticleRenderer
								prefix="Customer_Posttypecustomer_ArticleBlocks_"
								content={wpCustomer?.postTypeCustomer?.articleBlocks}
							/>
					</div>
					<div className="md:col-span-6 lg:col-span-4 mt-12 md:mt-8 lg:mt-16 sidebar-content relative">
						<ul className="bg-white p-8 md:p-11 pb-2 rounded-lg sticky top-24">
						<li className="mb-9">
							<h4 className="font-body text-forest font-bold mb-2">{customerStory.sidebar.heading}</h4>
							<div className="prose" dangerouslySetInnerHTML={{ __html: customerStory.sidebar.description }} />
							<p><a href={customerStory.sidebar.link.url} target={customerStory.sidebar.link.target}>{customerStory.sidebar.link.title}</a></p>
						</li>
						<li className="mb-9">
							<h4 className="font-body text-forest font-bold mb-2">Headquarters</h4>
							<div className="prose" dangerouslySetInnerHTML={{ __html: customerStory.sidebar.headquarters }} />
						</li>
						<li className="mb-9">
							<h4 className="font-body text-forest font-bold mb-2">Lender Type</h4>
							<div className="prose" dangerouslySetInnerHTML={{ __html: customerStory.sidebar.lenderType }} />
						</li>	
						</ul>
					</div>
				</div>
			</section>

			<section>
				<CustomersPostsList
					columns="3"
					headingClasses="text-h3"
					heading="See how we helped others like you"
					showCategories={false}
					listItems={allWpCustomer.nodes.slice(0, 3)}
				/>
			</section>

    </Layout>
  );
}

export const pageQuery = graphql`
  query customerSingle($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wpPost(id: { eq: $id }) {
      ...GeneratedWpPost
    }
    wp {
      ...GeneratedWp
    }
		allWpCustomer {
			nodes {
				title
				uri
				postTypeCustomer {
					previewName
					previewContent
					previewLogo {
						localFile {
							url
						}
					}
					previewImage {
						localFile{
							publicURL
						}
					}
				}
			}
		}
		wpCustomer(id: { eq: $id }) {
			title
			uri
			postTypeCustomer {
				articleBlocks{
					... on WpCustomer_Posttypecustomer_ArticleBlocks_Text {
							fieldGroupName
							text
					}
					... on WpCustomer_Posttypecustomer_ArticleBlocks_Image {
							fieldGroupName
							image { ...Image }
					}
					... on WpCustomer_Posttypecustomer_ArticleBlocks_Quote {
							by{
									fieldGroupName
									name
									role
							}
							fieldGroupName
							layout
							logo { ...Image }
							quote
					}
					... on WpCustomer_Posttypecustomer_ArticleBlocks_Bullets {
							bullets{
									fieldGroupName
									text
							}
							fieldGroupName
							heading
					}
			}
				addIconGrid
				iconGridHeading
				iconGridSubheading
				mastheadContent
				mastheadHeading
				mastheadImage {
					localFile {
						publicURL
					}
				}
				mastheadLink {
					link {
						title
						target
						url
					}
				}
				mastheadSubheading
				mastheadLogo {
					localFile {
						publicURL
					}
				}
				gridItems {
					content
					fieldGroupName
					icon {
						localFile {
							publicURL
						}
					}
				}
				sidebar {
					description
					heading
					fieldGroupName
					headquarters
					lenderType
					link {
						target
						title
						url
					}
				}
			}
    }
  }
`;
